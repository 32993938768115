import { parseISO } from 'date-fns'
import { drop, orderBy } from 'lodash'

import * as types from '../actions/ActionTypes'
import process from '../environment.json'
import { getNowTimestamp } from '../external/utilities/DateUtils'
import { cloneDeep, isEmpty } from '../external/utilities/GeneralUtils'
import { isDayAfter } from '../external/utilities/isDayAfter'
import { isDayBefore } from '../external/utilities/isDayBefore'
import { FEEDBACK_GROUPS } from '../utilities/FeedbackUtils'
import {
  getBackendLocalState,
  saveBackendLocalState,
  savePendingPOSItemSelectionData,
} from '../utilities/LocalStateUtils'

let txnInProgress1 = 0

const syncAvailableCarts = (state) => {
  try {
    const data = state.cart.currentCart

    return {
      ...state.cart,
      currentCart: {
        ...(Array.isArray(data) ? data[0] : data),
      },
    }
  } catch (e) {
    console.error(e)
  }
}

export const env = (key, defaultValue) => process.env[key] ?? defaultValue

export const initialStateClean = {
  isLoading: false,
  loaderText: '',
  showLoader: false,
  hackTempRedirectInd: false,
  pageInits: {
    mainApp: false,
    locale: false,
    siteAndBookingDetails: false,
    cartConfirmation: false,
    facility: false,
  },
  counters: {
    feedback: { request: 0 },
    redirect: { request: 0 },
  },
  error: {
    isError: false,
    errorData: {},
  },
  intl: {
    locale: 'en-US',
    messages: {},
  },
  rootInfo: {
    theme: 'ra',
    brandName: 'RA',
  },
  config: {
    main: {},
    home: {},
    brand: {},
    meta: {},
    facility: {},
    facility1: {},
    contract: {},
    product: {},
    bookingForm: {},
    client: {},
    feedback: {
      currentSelection: FEEDBACK_GROUPS.NONCHECKOUT,
      cartDetailedProcessResult: null,
      checkoutConfig: {},
      nonCheckoutConfig: {},
    },
    countryStateList: {},
    mapMain: {},
  },
  user: { profile: {} },
  cart: {
    failures: [],
    inventoryLockCtr: 0,
    inventoryLock: {},
    currentCart: {},
    itemBookingOptions: {},
    itemBookingOptionsSubmitSubFormCtr: 0,
    lastItemBookingSupplementInfoRequired: null,
    itemBookingSupplementOptions: {},
    updateProductBookingSupplementDetailsCtr: 0,
    itemFacilityProducts: {},
    detailedProcessResult: {},
    processResult: {},
    verifyGiftCardResult: {},
    verifyGiftCardCtr: 0,
    checkoutOffer: {},
  },
  has_purchased_apex_before: true,
  availableSWQuotes: [],
  evaultWasMounted: false,
  chubcart: {
    cartId: null,
    cartItems: [],
    cart_status: 'OPEN',
    created_at: null,
    is_anonymous: false,
    updated_at: null,
    user_id: null,
  },
  chubcartConfig: {
    default: {
      allow_cart_expansion_insurance: env(
        'CHUB_ALLOW_CART_EXPANSION_ENSURANCE',
        false,
      ),
      allow_partial_payments: env('CHUB_ALLOW_PARTIAL_PAYMENTS', false),
      chub_cart: env('CHUB_CART', false),
      apex_club: env('APEX_CLUB', false),
      facility_header: env('FACILITY_HEADER', false),
      chub_login: env('CHUB_LOGIN', false),
      chub_recaptcha: env('CHUB_RECAPTCHA', false),
    },
    ui: {
      show_toggle_cart_modal: false,
    },
  },
  chubcartResponse: [],
  addonOfferStatuses: [],
  offer: {
    current: {},
    processResult: {},
  },
  favorites: {
    initCtr: 0,
    favoritesList: {},
    lists: { test: 'test' },
    addToFavoritesCtr: 0,
    removeFromFavoritesCtr: 0,
    placesSearchTerm: null,
    placesSelectedObj: {},
    placesSearchResultsCache: {},
  },
  search: {
    searchTerm: null,
    selectedSearchObj: {},
    searchResults: {},
    searchSuggestions: {},
    searchSuggestionsContracts: [],
    searchSuggestionsWeb: [],
    searchFilter: {},
    quickSearch: {
      web: [],
      webCache: {},
      internal: [],
      internalCache: {},
      contracts: [],
    },
  },
  productSearch: {
    searchCriteria: {},
    searchResults: {},
    selectedProduct: {},
    selectedProductId: '',
    selectedProductRates: {},
    productCampingInfo: {},
    productDayPassInfo: {},
    productHourlySitesInfo: {},
  },
  productNotification: {
    searchResults: {},
    lastNotification: {},
    notificationCtr: 0,
  },
  facility: { facility: {}, error: null },
  txnTimeStamps: {
    cart: null,
    user: null,
  },
  listCampground: { config: {} },
  myAccount: {
    dashboard: {},
    campingclub: {},
    reservations: {
      current: {},
      past: {},
      reservation: {},
      ratingReservation: {},
      cancel: {},
      preCheckin: {},
      lastPreCheckinResult: {},
      payment: {},
      change: {},
      lastChangeResult: {},
      changeSubmitCtr: 0,
      changeSubmitSubFormCtr: 0,
      cancelChangeSubmitCtr: 0,
      changeSupplementDetails: {},
      updateChangeReservationSupplementDetailsCtr: 0,
      changeConfirmation: {},
      changeConfirmationResult: {},
      lastRatingReservationId: null,
      requestConfirmationEmailResponse: {},
      requestConfirmationEmailCtr: 0,
    },
    notifications: {},
    applications: {},
    passes: {
      current: {},
      expired: {},
    },
    vouchers: {},
    points: {
      list: {},
      details: {},
    },
    equipment: {
      list: {},
      details: {},
      updateCtr: 0,
      removeCtr: 0,
    },
    profile: {},
    campClub: {
      profile: {},
      updateCtr: 0,
    },
  },
  pos: {
    item: {},
    pendingPOSItemSelectionData: {},
    posAddToCartCtr: 0,
    posAddPendingToCartCtr: 0,
  },
  info: { currentItem: {} },
  advertise: { inquiry: {} },
  redirect: { redirectConfig: {} },
  launchURL: '',
  launchRoute: '',
  launchDataUpdateSkipCtr: 0,
  test: {
    pageOneData: {},
    pageTwoData: {},
    dataPlus: {},
    formData: {},
    dynamicContent: {},
  },
}

export const initialState = cloneDeep(initialStateClean)

function getTimeStamp() {
  return getNowTimestamp()
}

function addTxn() {
  txnInProgress1 += 1
}

export function removeTxn() {
  if (txnInProgress1 > 0) {
    txnInProgress1 -= 1
  }
}

export function getIsLoading() {
  if (txnInProgress1 > 0) {
    return true
  }

  return false
}

function updateLocale(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    intl: {
      ...state.intl,
      messages: data,
      locale: data.locale,
    },
    pageInits: {
      ...state.pageInits,
      locale: true,
    },
  }
}

function updateRootInfo(state = initialState, data) {
  return {
    ...state,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    rootInfo: data,
  }
}

function updateUserInfo(state = initialState, profile) {
  return {
    ...state,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    user: { profile },
  }
}

function updateFacilityInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    config: {
      ...state.config,
      facility: data[1]?.productSearchConfig,
      facility1: data[1]?.facilityConfig,
      contract: data[1]?.contractConfig,
    },
    facility: {
      ...state.facility,
      facility: data[0],
      error: data[0] ? undefined : state.facility.error,
    },
    pageInits: {
      ...state.pageInits,
      facility: true,
    },
  }
}
function updateFacilityInitFailed(state = initialState, data) {
  return {
    ...state,
    facility: {
      ...state.facility,
      error: {
        message: data.message,
        status: data.response?.status,
      },
    },
  }
}

function updateProductsInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productSearch: {
      ...state.productSearch,
      selectedProduct: getSelectedProduct(
        data[0],
        state.productSearch.selectedProductId,
      ),
      searchResults: data[0],
      productCampingInfo: data[1],
    },
  }
}

function updateProductsDaypassesInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productSearch: {
      ...state.productSearch,
      searchResults: data[0],
      productDayPassInfo: data[1],
    },
  }
}

function updateProductsHourlySitesInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productSearch: {
      ...state.productSearch,
      searchResults: data[0],
      productHourlySitesInfo: data[1],
    },
  }
}

function updateProductAndBookingInit(state = initialState, data) {
  removeTxn()
  let selProd = {}
  if (!isEmpty(data[2])) {
    selProd = data[2]
  }

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    facility: {
      ...state.facility,
      facility: data[0],
    },
    productSearch: {
      ...state.productSearch,
      selectedProduct: selProd,
      selectedProductRates: getProductRates(selProd),
    },
    config: {
      ...state.config,
      facility1: data[1].facilityConfig,
      contract: data[1].contractConfig,
    },
    pageInits: {
      ...state.pageInits,
      siteAndBookingDetails: true,
    },
  }
}

function updateUpdateProductAndBooking(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productSearch: {
      ...state.productSearch,
      selectedProductRates: getProductRates(null, data[0]),
    },
  }
}

function updateProductNotificationInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productNotification: {
      ...state.productNotification,
      searchResults: data[0],
    },
  }
}

function updateCreateProductNotification(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productNotification: {
      ...state.productNotification,
      lastNotification: data[0],
      notificationCtr: state.productNotification.notificationCtr + 1,
    },
  }
}

function updateProductBookingDetailsInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      itemBookingOptions: data[0],
      itemFacilityProducts: data[1],
    },
  }
}

function updateSubmitProductBookingDetailsSubForm(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      itemBookingOptions: data[0],
      itemBookingOptionsSubmitSubFormCtr:
        state.cart.itemBookingOptionsSubmitSubFormCtr + 1,
    },
  }
}

function updateProductBookingSupplementDetails(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      itemBookingSupplementOptions: data[0],
    },
  }
}

function updateUpdateProductBookingSupplementDetails(
  state = initialState,
  data,
) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts({
        ...state,
        cart: { ...state.cart, currentCart: data[0].shopCart },
      }),
      itemBookingSupplementOptions: data[0],
      updateProductBookingSupplementDetailsCtr:
        state.cart.updateProductBookingSupplementDetailsCtr + 1,
    },
  }
}

function updateRequestData(state = initialState, data) {
  addTxn()

  return {
    ...state,
    ...(data.fwdAction === types.CHUB_CHECKOUT
      ? {
          loaderText:
            'Processing payment. Please do not use the back button or refresh the page.',
        }
      : {}),
    isLoading:
      data.fwdAction === types.CHUB_GET_ADDON_OFFER_STATUSES
        ? false
        : getIsLoading(state),
    hackTempRedirectInd: false,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    pageInits: getPageInitsOnRequestStart(data, state.pageInits),
  }
}

function updatReceiveError(state = initialState, errors, failSilently = false) {
  removeTxn()

  const hasMultipleErrors = Array.isArray(errors)

  // if (!hasMultipleErrors) {
  //   if (isEmpty(errors) || isEmpty(errors.msgKey)) {
  //     errors = errMsgs.backend
  //   }
  // }

  return {
    ...state,
    isLoading: getIsLoading(state),
    loaderText: '',
    ...(failSilently
      ? { error: {}, isError: false }
      : {
          error: {
            ...state.error,
            isError: true,
            errorData: hasMultipleErrors ? errors : [errors],
          },
        }),
  }
}

function updateShowLoader(state = initialState, data) {
  return {
    ...state,
    showLoader: data,
  }
}

function updateSearchObj(state = initialState, data) {
  return {
    ...state,
    hackTempRedirectInd: true,
    search: {
      ...state.search,
      searchTerm: data.searchTerm,
      selectedSearchObj: data,
    },
  }
}

function updateSearchFilterObj(state = initialState, data) {
  return {
    ...state,
    search: {
      ...state.search,
      searchFilter: data,
    },
  }
}

function updateProductSearchCriteria(state = initialState, data) {
  return {
    ...state,
    productSearch: {
      ...state.productSearch,
      searchCriteria: data,
    },
  }
}

function resetProductSearchCriteria(state = initialState) {
  return {
    ...state,
    productSearch: {
      ...state.productSearch,
      searchCriteria: {},
    },
  }
}

function updateSearchResultsInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    search: {
      ...state.search,
      searchResults: data[0],
      searchSuggestions: {},
      searchSuggestionsContracts: [],
      searchSuggestionsWeb: [],
    },
  }
}

function updateSearchSuggestionsInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    search: {
      ...state.search,
      searchResults: {},
      searchSuggestions: data[1],
      searchSuggestionsContracts: data[0],
      searchSuggestionsWeb: data[2],
    },
  }
}

function updateSelectedProduct(state = initialState, data) {
  return {
    ...state,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    productSearch: {
      ...state.productSearch,
      selectedProduct: data.product,
      selectedProductId: data.productId,
    },
  }
}

function updateCart(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: syncAvailableCarts({
      ...state,
      cart: { ...state.cart, currentCart: data[0] },
    }),
    txnTimeStamps: {
      ...state.txnTimeStamps,
      cart: getTimeStamp(),
    },
  }
}

function updateLockInventory(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      inventoryLockCtr: state.cart.inventoryLockCtr + 1,
      inventoryLock: data[0],
    },
  }
}

function updateCartItem(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts({
        ...state,
        cart: { ...state.cart, currentCart: data[0].shopCart },
      }),
      lastItemBookingSupplementInfoRequired: data[0].otherDetailsRequired,
    },
    txnTimeStamps: {
      ...state.txnTimeStamps,
      cart: getTimeStamp(),
    },
  }
}

function a1UpdateProcessCart(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...state.cart,
      ...(data[0]
        ? {
            processResult: { ...state.cart.processResult, ...data[0] },
            currentCart: data[0].shoppingCart,
          }
        : {}),
    },
  }
}

function chubUpdateProcessCart(state = initialState, data) {
  removeTxn()

  const { response, failures = { faults: [] } } = data
  const a1Response = response.find((item) => item.vendor === 'a1')
  const currentCart = a1Response.paymentResponse.shoppingCart

  return {
    ...state,
    isLoading: getIsLoading(state),
    loaderText:
      'Redirecting to confirmation page. Please do not use the back button or refresh the page.',
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts({
        ...state,
        cart: {
          ...state.cart,
          failures,
          processResult: a1Response.paymentResponse,
          currentCart: {
            ...currentCart,
            contractViewList: [],
          },
        },
      }),
    },
  }
}

function chubToggleCart(state = initialState, data) {
  return {
    ...state,
    chubcartConfig: {
      ...state.chubcartConfig,
      default: {
        ...state.chubcartConfig.default,
        ...data,
      },
    },
  }
}

function chubToggleCartModal(state = initialState, data) {
  return {
    ...state,
    chubcartConfig: {
      ...state.chubcartConfig,
      ui: {
        ...state.chubcartConfig.ui,
        show_toggle_cart_modal: data,
      },
    },
  }
}

function chubGetApexClubSubscriptionStatus(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    has_purchased_apex_before: data.customerExists,
  }
}

function updateCheckoutInit(state = initialState, data) {
  removeTxn()
  let { countryStateList } = state.config
  if (data[1] !== undefined) {
    countryStateList = data[1]
  }

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      checkoutOffer: data[0] || state.cart.checkoutOffer,
    },
    config: {
      ...state.config,
      countryStateList,
    },
  }
}

function updateVerifyGiftCard(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      verifyGiftCardResult: data[0],
      verifyGiftCardCtr: state.cart.verifyGiftCardCtr + 1,
    },
  }
}

function updateGetCart(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: syncAvailableCarts({
      ...state,
      cart: { ...state.cart, currentCart: data },
    }),
    txnTimeStamps: {
      ...state.txnTimeStamps,
      cart: getTimeStamp(),
    },
  }
}

function updateGetDetailedCartProcessResult(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    loaderText: '',
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    cart: {
      ...syncAvailableCarts(state),
      detailedProcessResult: {
        ...(state.cart.failures ? { failures: state.cart.failures } : {}),
        ...data[0],
      },
    },
    config: {
      ...state.config,
      feedback: {
        ...state.config.feedback,
        checkoutConfig: data[1],
      },
    },
    pageInits: {
      ...state.pageInits,
      cartConfirmation: true,
    },
  }
}

function updateGetOffer(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    offer: {
      ...state.offer,
      current: data,
    },
  }
}

function updateProcessOffer(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    offer: {
      ...state.offer,
      processResult: data,
    },
  }
}

function updateGetPOSItemInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    pos: {
      ...state.pos,
      item: data[0],
    },
  }
}

function updateAddPOSItemToCart(state = initialState, data) {
  savePendingPOSItemSelectionData({})

  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    pos: {
      ...state.pos,
      posAddToCartCtr: state.pos.posAddToCartCtr + 1,
      pendingPOSItemSelectionData: {},
    },
    cart: {
      ...syncAvailableCarts({
        ...state,
        cart: { ...state.cart, currentCart: data[0] },
      }),
    },
    txnTimeStamps: {
      ...state.txnTimeStamps,
      cart: getTimeStamp(),
    },
  }
}

function updateAddPendingPOSItemToCart(state = initialState, data) {
  savePendingPOSItemSelectionData(data)

  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    pos: {
      ...state.pos,
      posAddPendingToCartCtr: state.pos.posAddPendingToCartCtr + 1,
    },
  }
}

function updateAppInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    intl: {
      ...state.intl,
      messages: data[0],
      locale: data[0].locale,
    },
    config: {
      ...state.config,
      main: data[1],
      home: data[2],
      brand: data[3],
      feedback: {
        ...state.config.feedback,
        nonCheckoutConfig: data[5],
      },
      meta: data[6],
    },
    user: {
      ...state.user,
      profile: data[4],
    },
    txnTimeStamps: {
      ...state.txnTimeStamps,
      user: getTimeStamp(),
    },
    pageInits: {
      ...state.pageInits,
      mainApp: true,
      locale: true,
    },
  }
}

function updateSendFeedback(state = initialState) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
  }
}

function updateRequestFeedback(state = initialState, data) {
  return {
    ...state,
    counters: {
      ...state.counters,
      feedback: {
        ...state.counters.feedback,
        request: state.counters.feedback.request + 1,
      },
    },
    config: {
      ...state.config,
      feedback: {
        ...state.config.feedback,
        currentSelection: data[0],
        cartDetailedProcessResult: data[1],
      },
    },
  }
}

function updateRequestRedirect(state = initialState, data) {
  return {
    ...state,
    counters: {
      ...state.counters,
      redirect: {
        ...state.counters.redirect,
        request: state.counters.redirect.request + 1,
      },
    },
    redirect: {
      ...state.redirect,
      redirectConfig: data[0],
    },
  }
}

function updateFavoritesInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    favorites: {
      ...state.favorites,
      favoritesList: data[0],
      lists: data[1],
      initCtr: state.favorites.initCtr + 1,
    },
  }
}

function updateAddToFavoritesComplete(state = initialState) {
  const updatedCtr = state.favorites.addToFavoritesCtr + 1

  return {
    ...state,
    favorites: {
      ...state.favorites,
      addToFavoritesCtr: updatedCtr,
    },
  }
}

function updateRemoveFromFavoritesComplete(state = initialState) {
  const updatedCtr = state.favorites.removeFromFavoritesCtr + 1

  return {
    ...state,
    favorites: {
      ...state.favorites,
      removeFromFavoritesCtr: updatedCtr,
    },
  }
}

function updateGetFavoritesSearchPlaces(state = initialState, data) {
  const wc = state.favorites.placesSearchResultsCache
  wc[data.searchTerm.toLowerCase()] = data.results

  return {
    ...state,
    favorites: {
      ...state.favorites,
      placesSearchResultsCache: wc,
    },
  }
}

function updateRouteChangeAction(state = initialState) {
  return {
    ...state,
    hackTempRedirectInd: false,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
  }
}

function updateQuickSearchWeb(state = initialState, data) {
  const wc = state.search.quickSearch.webCache
  wc[data.searchTerm.toLowerCase()] = data.results

  return {
    ...state,
    search: {
      ...state.search,
      quickSearch: {
        ...state.search.quickSearch,
        web: data.results,
        webCache: wc,
      },
    },
  }
}

function updateQuickSearchContract(state = initialState, data) {
  // TODO - Add caching
  return {
    ...state,
    search: {
      ...state.search,
      quickSearch: {
        ...state.search.quickSearch,
        contracts: data.results,
      },
    },
  }
}

function updateQuickSearchInternal(state = initialState, data) {
  const ic = state.search.quickSearch.internalCache
  ic[data.searchTerm.toLowerCase()] = data.results

  return {
    ...state,
    search: {
      ...state.search,
      quickSearch: {
        ...state.search.quickSearch,
        internal: data.results,
        internalCache: ic,
      },
    },
  }
}

function updateQuickSearchClearAll(state = initialState) {
  // TODO - Add caching
  return {
    ...state,
    search: {
      ...state.search,
      quickSearch: {
        ...state.search.quickSearch,
        web: {},
        internal: {},
        contracts: {},
      },
    },
  }
}

/* My Account */
const mapS2nToA1 = (record) => {
  return {
    ...record,
    partner: 's2n',
    balance: 0.0,
    balanceFormatted: '$0.00',
    reservationNumber: record.orderId,
    purchaseType: record.type,
    arrivalDate: record.checkIn,
    departureDate: record.checkOut,
  }
}

const filterReservationByCheckoutDate = (record, current) => {
  const now = new Date()
  const checkoutDate = parseISO(record.checkOut)

  return current
    ? isDayAfter(checkoutDate, now)
    : isDayBefore(checkoutDate, now)
}

const sortReservations = (reservations, query) => {
  switch (query.sortby) {
    case 'location':
      return orderBy(reservations, ['parkName'], [query.sortdir])
    case 'type':
      return orderBy(reservations, ['purchaseType'], [query.sortdir])
    case 'date':
      return orderBy(reservations, (record) => new Date(record.departureDate), [
        query.sortdir,
      ])
    default:
      return orderBy(reservations, (record) => new Date(record.departureDate), [
        'desc',
      ])
  }
}

const paginate = (items, currentPage, pageSize) => {
  return drop(items, (currentPage - 1) * pageSize).slice(0, pageSize)
}

const mergeReservations = ([chubResponse, s2nResponse], { current }) => {
  const { records: chubRecords = [] } = chubResponse
  const {
    records: s2nBaseRecords = [],
    query = {},
    pagination = {},
  } = s2nResponse

  const s2nRecords = s2nBaseRecords
    .filter((record) => filterReservationByCheckoutDate(record, current))
    .map(mapS2nToA1)

  const records = sortReservations([...chubRecords, ...s2nRecords], query)

  return {
    ...chubResponse,
    records: paginate(records, pagination.rcp || 1, 20),
  }
}

const updateDashboardInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      dashboard: data[0],
    },
  }
}

const updateReservationsCurrent = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        current: mergeReservations(data, { current: true }),
      },
    },
  }
}

const updateReservationsPast = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        past: mergeReservations(data, { current: false }),
      },
    },
  }
}

const updateReservationDetailsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        reservation: data[0],
      },
    },
  }
}

const updateReservationCancelInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        cancel: { ...data[0], ...data[1] },
      },
    },
  }
}

const updateCancelReservation = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        cancel: data[0],
      },
    },
  }
}

const updateReservationPreCheckinInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        preCheckin: data[0],
      },
    },
  }
}

const updatePreCheckinReservation = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        lastPreCheckinResult: data[0],
      },
    },
  }
}

const updateReservationPaymentInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        payment: data[0],
      },
    },
  }
}

const updateReservationChangeInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        change: data[0],
      },
    },
  }
}

const updateChangeReservation = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        lastChangeResult: data[0],
        changeSubmitCtr: state.myAccount.reservations.changeSubmitCtr + 1,
      },
    },
  }
}

const updateChangeReservationSiteDate = function (state = initialState) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
  }
}

const updateCancelChangeReservation = function (state = initialState) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        cancelChangeSubmitCtr:
          state.myAccount.reservations.cancelChangeSubmitCtr + 1,
      },
    },
  }
}

const updateSubmitChangeReservationSubForm = function (
  state = initialState,
  data,
) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        change: data[0],
        changeSubmitSubFormCtr:
          state.myAccount.reservations.changeSubmitSubFormCtr + 1,
      },
    },
  }
}

const updateReservationChangeSupplementDetailsInit = function (
  state = initialState,
  data,
) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        changeSupplementDetails: data[0],
      },
    },
  }
}

const updateUpdateReservationChangeSupplementDetails = function (
  state = initialState,
  data,
) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        changeSupplementDetails: data[0],
        updateChangeReservationSupplementDetailsCtr:
          state.myAccount.reservations
            .updateChangeReservationSupplementDetailsCtr + 1,
      },
    },
  }
}

const updateReservationChangeConfirmationInit = function (
  state = initialState,
  data,
) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        changeConfirmation: { ...data[0], ...data[1] },
      },
    },
  }
}

const updateConfirmChangeReservation = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    loaderText: '',
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        changeConfirmationResult: data[0],
      },
    },
  }
}

const updatePayReservation = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        payment: data[0],
      },
    },
  }
}

const updateNotificationsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      notifications: data[0],
    },
  }
}

const updateRemoveNotification = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      notifications: data[1],
    },
  }
}

const updateApplicationsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      applications: data[0],
    },
  }
}

const updatePassesCurrentInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      passes: {
        ...state.myAccount.passes,
        current: data[0],
      },
    },
  }
}

const updatePassesExpiredInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      passes: {
        ...state.myAccount.passes,
        expired: data[0],
      },
    },
  }
}

const updateVouchersInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      vouchers: data[0],
    },
  }
}

const updatePointsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      points: {
        ...state.myAccount.points,
        list: data[0],
      },
    },
  }
}

const updatePointsDetailsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      points: {
        ...state.myAccount.points,
        details: data[0],
      },
    },
  }
}

const updateEquipmentInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      equipment: {
        ...state.myAccount.equipment,
        list: data[0],
      },
    },
  }
}

const updateEquipmentDetailsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      equipment: {
        ...state.myAccount.equipment,
        details: data[0],
      },
    },
  }
}

const updateRemoveEquipment = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      equipment: {
        ...state.myAccount.equipment,
        list: data[1],
        removeCtr: state.myAccount.equipment.removeCtr + 1,
      },
    },
  }
}

const updateUpdateEquipment = function (state = initialState) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      equipment: {
        ...state.myAccount.equipment,
        updateCtr: state.myAccount.equipment.updateCtr + 1,
      },
    },
  }
}

const updateProfileInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      profile: data[0],
    },
  }
}

const updateCampClubProfileInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      campClub: {
        ...state.myAccount.campClub,
        profile: data[0],
      },
    },
  }
}

const updateCampClubProfile = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      campClub: {
        ...state.myAccount.campClub,
        updateCtr: state.myAccount.campClub.updateCtr + 1,
        profile: data[1],
      },
    },
  }
}

const updateRateReservationInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        ratingReservation: data[0],
      },
    },
  }
}

const updateRateReservation = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        lastRatingReservationId: data[0],
      },
    },
  }
}

const updateRequestConfirmationEmail = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    myAccount: {
      ...state.myAccount,
      reservations: {
        ...state.myAccount.reservations,
        requestConfirmationEmailResponse: data[0],
        requestConfirmationEmailCtr:
          state.myAccount.reservations.requestConfirmationEmailCtr + 1,
      },
    },
  }
}

const updateContactUsInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    config: {
      ...state.config,
      client: data[0],
    },
  }
}

const updateAdvertiseInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    advertise: {
      ...state.advertise,
      inquiry: data[0],
    },
  }
}

const updateInfoInit = function (state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    info: {
      ...state.info,
      currentItem: data[0],
    },
  }
}

function getPageInitsOnRequestStart(actionData, pageInits) {
  switch (actionData.fwdAction) {
    case types.GET_FACILITY_INIT:
      pageInits.facility = false
      break
    case types.GET_PRODUCT_AND_BOOKING_INIT:
      pageInits.siteAndBookingDetails = false
      break
    case types.GET_DETAILED_CART_PROCESS_RESULT:
      pageInits.cartConfirmation = false
      break
    case types.GET_LOCALE:
      pageInits.locale = false
      break
    case types.GET_APP_INIT:
      pageInits.mainApp = false
      break
    default:
      break
  }

  return pageInits
}

/* Testing */
function updatePageOneData(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    test: {
      ...state.test,
      pageOneData: data,
    },
  }
}

function updateListCampgroundInit(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    listCampground: {
      ...state.listCampground,
      config: data[0],
    },
  }
}

function launchDataUpdateSkip(state = initialState) {
  return {
    ...state,
    launchDataUpdateSkipCtr: state.launchDataUpdateSkipCtr + 1,
  }
}

/* Testing */
function updatePageTwoData(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    test: {
      ...state.test,
      pageTwoData: data[0],
      dataPlus: data[1],
    },
  }
}

/* Testing */
function updateFormTestData(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    test: {
      ...state.test,
      formData: data[0],
      dynamicContent: data[1],
    },
  }
}

function updateFormTestResponse(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    test: {
      ...state.test,
      formData: data[0],
    },
  }
}

function updateTestSubmitSubFormResponse(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    hackTempRedirectInd: true,
    error: {
      ...state.error,
      isError: false,
      errorData: {},
    },
    test: {
      ...state.test,
      formData: data[0],
    },
  }
}

function saveLocalState(state = initialState) {
  return saveBackendLocalState(state)
}

function getLocalState(state = initialState) {
  return getBackendLocalState(state)
}

function getSelectedProduct(products, productId) {
  let product = {}
  if (!isEmpty(products) && !isEmpty(productId)) {
    for (let i = 0; i < products.length; i++) {
      if (products[i].id === productId) {
        product = products[i]
        break
      }
    }
  }

  return product
}

function getProductRates(product = null, rateObject = null) {
  let rates = {}
  if (!isEmpty(product) && !isEmpty(product.records)) {
    rates = product.records[0].details.rates
  }

  if (!isEmpty(rateObject) && !isEmpty(rateObject.campingFees)) {
    rates = rateObject.campingFees
  }

  return rates
}

function getCamperHubFacility(state, data = []) {
  removeTxn()

  const [camperhubFacility, camperhubFacilityEnums] = data

  return {
    ...state,
    isLoading: getIsLoading(state),
    camperhubFacility: camperhubFacility && {
      ...camperhubFacility,
      enums: camperhubFacilityEnums,
    },
  }
}

function updateCamperHubFacilityAvailableDates(state, data) {
  return {
    ...state,
    camperhubFacility: {
      ...state.camperhubFacility,
      availableDates: data ?? [],
    },
  }
}

/* CHUB Cart */
function chubUpdateCart(state = initialState, data) {
  try {
    removeTxn()

    return {
      ...state,
      isLoading: getIsLoading(state),
      loaderText: '',
      chubcart: data[0],
      cart: syncAvailableCarts({ ...state, chubcart: data[0] }),
    }
  } catch (e) {
    console.error(e)
  }
}

function chubGetConfig(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    chubcartConfig: {
      ...state.chubcartConfig,
      ...data[0],
    },
  }
}

function chubDeclineQuote(state = initialState) {
  removeTxn()

  return { ...state, isLoading: getIsLoading(state) }
}

function setEvaultWasMounted(state = initialState, data) {
  return {
    ...state,
    evaultWasMounted: data,
  }
}

function setAvailableSWQuotes(state = initialState, data) {
  const { quoteId, available } = data

  const availableSWQuotes = available
    ? [...state.availableSWQuotes, quoteId]
    : Array.from(
        new Set(state.availableSWQuotes.filter((id) => quoteId !== id)),
      )

  return {
    ...state,
    availableSWQuotes,
  }
}

function updateChubCartResponse(state = initialState, data) {
  return {
    ...state,
    chubcartResponse: data,
  }
}

function chubGetAddonOfferStatuses(state = initialState, data) {
  removeTxn()

  return {
    ...state,
    addonOfferStatuses: data,
    isLoading: false,
  }
}

function declineUnpurchasedSWQuotes(state = initialState) {
  removeTxn()

  return {
    ...state,
    isLoading: getIsLoading(state),
    swQuotes: [],
  }
}

function backend(state = initialState, action = null) {
  switch (action.type) {
    case types.REQUEST_DATA:
      return updateRequestData(state, action.data)
    case types.RECEIVE_ERROR:
      return updatReceiveError(state, action.data, action?.failSilently)
    case types.SHOW_LOADER:
      return updateShowLoader(state, action.data)
    case types.SAVE_LOCAL_STATE:
      return saveLocalState(state, action)
    case types.GET_LOCAL_STATE:
      return getLocalState(state, action)
    case types.GET_LOCALE:
      return updateLocale(state, action.data)
    case types.SET_ROOT_INFO:
      return updateRootInfo(state, action.data)
    case types.GET_FACILITY_INIT:
      return updateFacilityInit(state, action.data)
    case types.GET_FACILITY_INIT_FAILED:
      return updateFacilityInitFailed(state, action.data)
    case types.GET_PRODUCTS_INIT:
      return updateProductsInit(state, action.data)
    case types.GET_PRODUCTS_DAYPASSES_INIT:
      return updateProductsDaypassesInit(state, action.data)
    case types.GET_PRODUCTS_HOURLY_SITES_INIT:
      return updateProductsHourlySitesInit(state, action.data)
    case types.UPDATE_SEARCH_TERM:
      return updateSearchObj(state, action.data)
    case types.UPDATE_SEARCH_FILTER:
      return updateSearchFilterObj(state, action.data)
    case types.UPDATE_PRODUCT_SEARCH_CRITERIA:
      return updateProductSearchCriteria(state, action.data)
    case types.RESET_PRODUCT_SEARCH_CRITERIA:
      return resetProductSearchCriteria(state, action.data)
    case types.GET_SEARCH_RESULTS_INIT:
      return updateSearchResultsInit(state, action.data)
    case types.GET_SEARCH_SUGGESTIONS_INIT:
      return updateSearchSuggestionsInit(state, action.data)
    case types.UPDATE_SELECTED_PRODUCT:
      return updateSelectedProduct(state, action.data)
    case types.GET_PRODUCT_AND_BOOKING_INIT:
      return updateProductAndBookingInit(state, action.data)
    case types.UPDATE_PRODUCT_AND_BOOKING:
      return updateUpdateProductAndBooking(state, action.data)
    case types.GET_PRODUCT_NOTIFICATION_INIT:
      return updateProductNotificationInit(state, action.data)
    case types.CREATE_PRODUCT_NOTIFICATION:
      return updateCreateProductNotification(state, action.data)
    case types.GET_PRODUCT_DETAILS_INIT:
      return updateProductBookingDetailsInit(state, action.data)
    case types.SUBMIT_PRODUCT_BOOKING_DETAILS_SUB_FORM:
      return updateSubmitProductBookingDetailsSubForm(state, action.data)
    case types.GET_PRODUCT_BOOKING_SUPPLEMENT_DETAILS:
      return updateProductBookingSupplementDetails(state, action.data)
    case types.UPDATE_PRODUCT_BOOKING_SUPPLEMENT_DETAILS:
      return updateUpdateProductBookingSupplementDetails(state, action.data)
    case types.ADD_PRODUCT_TO_CART:
      return updateCart(state, action.data)
    case types.LOCK_INVENTORY:
      return updateLockInventory(state, action.data)
    case types.UPDATE_CART_ITEM:
      return updateCartItem(state, action.data)
    case types.UPDATE_CART_ITEM_DATES:
      return updateCart(state, action.data)
    case types.REMOVE_CART_ITEMS:
      return updateCart(state, action.data)
    case types.ABANDON_CART:
      return updateCart(state, action.data)
    case types.ADD_POS:
      return updateCart(state, action.data)
    case types.PROCESS_CART:
      return a1UpdateProcessCart(state, action.data)
    case types.GET_CHECKOUT_INIT:
      return updateCheckoutInit(state, action.data)
    case types.VERIFY_GIFT_CARD:
      return updateVerifyGiftCard(state, action.data)
    case types.GET_CART:
      return updateGetCart(state, action.data)
    case types.GET_DETAILED_CART_PROCESS_RESULT:
      return updateGetDetailedCartProcessResult(state, action.data)
    case types.GET_OFFER:
      return updateGetOffer(state, action.data)
    case types.PROCESS_OFFER:
      return updateProcessOffer(state, action.data)
    case types.GET_POS_ITEM_INIT:
      return updateGetPOSItemInit(state, action.data)
    case types.ADD_POS_ITEM_TO_CART:
      return updateAddPOSItemToCart(state, action.data)
    case types.ADD_PENDING_POS_ITEM_TO_CART:
      return updateAddPendingPOSItemToCart(state, action.data)
    case types.GET_APP_INIT:
      return updateAppInit(state, action.data)
    case types.GET_QUCK_SEARCH_WEB:
      return updateQuickSearchWeb(state, action.data)
    case types.GET_QUCK_SEARCH_CONTRACT:
      return updateQuickSearchContract(state, action.data)
    case types.GET_QUCK_SEARCH_INTERNAL:
      return updateQuickSearchInternal(state, action.data)
    case types.GET_QUCK_SEARCH_CLEAR_ALL:
      return updateQuickSearchClearAll(state, action.data)
    case types.SEND_FEEDBACK:
      return updateSendFeedback(state, action.data)
    case types.REQUEST_FEEDBACK:
      return updateRequestFeedback(state, action.data)
    case types.REQUEST_REDIRECT:
      return updateRequestRedirect(state, action.data)
    case types.GET_FAVORITES_INIT:
      return updateFavoritesInit(state, action.data)
    case types.ADD_TO_FAVORITES_COMPLETE:
      return updateAddToFavoritesComplete(state, action.data)
    case types.REMOVE_FROM_FAVORITES_COMPLETE:
      return updateRemoveFromFavoritesComplete(state, action.data)
    case types.GET_FAVORITES_SEARCH_PLACES:
      return updateGetFavoritesSearchPlaces(state, action.data)
    case types.ROUTE_CHANGE_ACTION:
      return updateRouteChangeAction(state, action.data)
    case types.GET_LIST_CAMPGROUND_INIT:
      return updateListCampgroundInit(state, action.data)
    case types.GET_DASHBOARD_INIT:
      return updateDashboardInit(state, action.data)
    case types.GET_RESERVATIONS_CUURENT:
      return updateReservationsCurrent(state, action.data)
    case types.GET_RESERVATIONS_PAST:
      return updateReservationsPast(state, action.data)
    case types.GET_RESERVATION_DETAILS_INIT:
      return updateReservationDetailsInit(state, action.data)
    case types.GET_RESERVATION_CANCEL_INIT:
      return updateReservationCancelInit(state, action.data)
    case types.CANCEL_RESERVATION:
      return updateCancelReservation(state, action.data)
    case types.GET_RESERVATION_PRE_CHECKIN_INIT:
      return updateReservationPreCheckinInit(state, action.data)
    case types.PRE_CHECKIN_RESERVATION:
      return updatePreCheckinReservation(state, action.data)
    case types.GET_RESERVATION_PAYMENT_INIT:
      return updateReservationPaymentInit(state, action.data)
    case types.GET_RESERVATION_CHANGE_INIT:
      return updateReservationChangeInit(state, action.data)
    case types.CHANGE_RESERVATION:
      return updateChangeReservation(state, action.data)
    case types.CHANGE_RESERVATION_SITE_DATE:
      return updateChangeReservationSiteDate(state, action.data)
    case types.CANCEL_CHANGE_RESERVATION:
      return updateCancelChangeReservation(state, action.data)
    case types.GET_RESERVATION_CHANGE_SUPPLEMENT_DETAILS_INIT:
      return updateReservationChangeSupplementDetailsInit(state, action.data)
    case types.UPDATE_RESERVATION_CHANGE_SUPPLEMENT_DETAILS:
      return updateUpdateReservationChangeSupplementDetails(state, action.data)
    case types.SUBMIT_CHANGE_RESERVATION_SUB_FORM:
      return updateSubmitChangeReservationSubForm(state, action.data)
    case types.GET_RESERVATION_CHANGE_CONFIRMATION_INIT:
      return updateReservationChangeConfirmationInit(state, action.data)
    case types.CONFIRM_CHANGE_RESERVATION:
      return updateConfirmChangeReservation(state, action.data)
    case types.PAY_RESERVATION:
      return updatePayReservation(state, action.data)
    case types.GET_NOTIFICATIONS_INIT:
      return updateNotificationsInit(state, action.data)
    case types.REMOVE_NOTIFICATION:
      return updateRemoveNotification(state, action.data)
    case types.GET_APPLICATIONS_INIT:
      return updateApplicationsInit(state, action.data)
    case types.GET_PASSES_CURRENT_INIT:
      return updatePassesCurrentInit(state, action.data)
    case types.GET_PASSES_EXPIRED_INIT:
      return updatePassesExpiredInit(state, action.data)
    case types.GET_VOUCHERS_INIT:
      return updateVouchersInit(state, action.data)
    case types.GET_POINTS_INIT:
      return updatePointsInit(state, action.data)
    case types.GET_POINTS_DETAILS_INIT:
      return updatePointsDetailsInit(state, action.data)
    case types.GET_EQUIPMENT_INIT:
      return updateEquipmentInit(state, action.data)
    case types.GET_EQUIPMENT_DETAILS_INIT:
      return updateEquipmentDetailsInit(state, action.data)
    case types.REMOVE_EQUIPMENT:
      return updateRemoveEquipment(state, action.data)
    case types.UPDATE_EQUIPMENT:
      return updateUpdateEquipment(state, action.data)
    case types.GET_PROFILE_INIT:
      return updateProfileInit(state, action.data)
    case types.GET_CAMP_CLUB_PROFILE_INIT:
      return updateCampClubProfileInit(state, action.data)
    case types.UPDATE_CAMP_CLUB_PROFILE:
      return updateCampClubProfile(state, action.data)
    case types.GET_RATE_RESERVATION_INIT:
      return updateRateReservationInit(state, action.data)
    case types.RATE_RESERVATION:
      return updateRateReservation(state, action.data)
    case types.REQUEST_CONFIRMATION_EMAIL:
      return updateRequestConfirmationEmail(state, action.data)
    case types.GET_CONTACT_US_INIT:
      return updateContactUsInit(state, action.data)
    case types.GET_ADVERTISE_INIT:
      return updateAdvertiseInit(state, action.data)
    case types.GET_INFO_INIT:
      return updateInfoInit(state, action.data)

    case types.LAUNCH_DATA_UPDATE_SKIP:
      return launchDataUpdateSkip(state, action)

    /* Testing */
    case types.GET_PAGE_ONE_DATA:
      return updatePageOneData(state, action.data)
    /* Testing */
    case types.GET_PAGE_TWO_DATA:
      return updatePageTwoData(state, action.data)
    /* Testing */
    case types.GET_FORM_TEST_DATA:
      return updateFormTestData(state, action.data)
    case types.POST_FORM_TEST_DATA:
      return updateFormTestResponse(state, action.data)
    case types.TEST_SUBMIT_SUB_FORM:
      return updateTestSubmitSubFormResponse(state, action.data)
    case types.UPDATE_CAMPER_HUB_FACILITY:
      return getCamperHubFacility(state, action.data)
    case types.UPDATE_CAMPER_HUB_FACILITY_AVAILABLE_DATES:
      return updateCamperHubFacilityAvailableDates(state, action.data)

    /* CamperHub Chances */
    case types.CHUB_CREATE_CART:
      return chubUpdateCart(state, action.data)
    case types.CHUB_CLAIM_CART:
      return chubUpdateCart(state, action.data)
    case types.CHUB_GET_CART_BY_ID:
      return chubUpdateCart(state, action.data)
    case types.CHUB_ADD_ITEM_TO_CART:
      return chubUpdateCart(state, action.data)
    case types.CHUB_REMOVE_ITEM_FROM_CART:
      return chubUpdateCart(state, action.data)
    case types.CHUB_GET_LATEST_CART_FROM_CURRENT_USER:
      return chubUpdateCart(state, action.data)
    case types.CHUB_GET_CART_CONFIG:
      return chubGetConfig(state, action.data)
    case types.CHUB_CHECKOUT:
      return chubUpdateProcessCart(state, action.data)
    case types.CHUB_TOGGLE_CART:
      return chubToggleCart(state, action.data)
    case types.CHUB_TOGGLE_CART_MODAL:
      return chubToggleCartModal(state, action.data)
    case types.CHUB_GET_APEX_CLUB_STATUS:
      return chubGetApexClubSubscriptionStatus(state, action.data[0])
    case types.CHUB_DECLINE_QUOTE:
      return chubDeclineQuote(state, action.data)
    case types.SET_EVAULT_WAS_MOUNTED:
      return setEvaultWasMounted(state, action.data)
    case types.SET_AVAILABLE_SW_QUOTES:
      return setAvailableSWQuotes(state, action.data)
    case types.UPDATE_CHUBCART_RESPONSE:
      return updateChubCartResponse(state, action.data)
    case types.DECLINE_UNPURCHASED_SW_QUOTES:
      return declineUnpurchasedSWQuotes(state, action.data)
    case types.CHUB_GET_ADDON_OFFER_STATUSES:
      return chubGetAddonOfferStatuses(state, action.data)
    case types.USER_DATA:
      return updateUserInfo(state, action.data)
    default:
      return state
  }
}

export default backend
